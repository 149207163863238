import * as React from 'react'

import Layout from '../layouts/layout'
import sty from 'src/layouts/styles/index'
import { LeftRight } from 'src/components/Main/ContentBox'
import { ArrowRight } from 'src/components/Icons'


const ContactPage = () => {

  const sx = {  // had problems to use that as variant in theme
    userSelect: 'text',
    flexDirection: ['column'],
    justifyContent: 'space-between',
    my: [9],  // same as in 'kontakt' page, in <Embed/>
  }

  return (
    <Layout seo={{ title: '404' }}>
      <sty.Flex variant='layoutWrapper' sx={sx}>
        <LeftRight>
          <sty.Heading1 color='alpisolRed' fontSize='80px !important' children={'404'} />
          <sty.Heading2 children={'Ooops.'} />
          <sty.Display children={'Tut uns leid. Die Seite konnte nicht gefunden werden.'} />
        </LeftRight>

        <LeftRight>
          <sty.Display>
            <ArrowRight /> <sty.Link to='/'>Zurück zur Startseite</sty.Link>
          </sty.Display>
        </LeftRight>
      </sty.Flex>
    </Layout>
  )
}

export default ContactPage
